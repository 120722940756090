/* Research.css */

/* Container for the Latest Research component */
.latest-research-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9; /* Light background for contrast */
}

/* Styling for each article card */
.article-card {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ffffff; /* White background for articles */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.article-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Title styling within the article card */
.article-card .MuiTypography-h6 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333333; /* Dark text for readability */
}

/* Abstract summary styling */
.article-card .MuiTypography-body1 {
  font-size: 1rem;
  color: #555555; /* Slightly lighter text for the summary */
  line-height: 1.6;
}

/* Loading text styling */
.latest-research-component .MuiTypography-root {
  font-size: 1.2rem;
  color: #777777;
}
