/* common.css */

/* General body styles */
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #F5F5F5; /* Light Grey */
    color: #2E2E2E; /* Dark Grey */
  }
  
  /* General link styles */
  a {
    text-decoration: none;
    color: inherit;
  }
  
  /* General card styles */
  .card {
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* General card heading styles */
  .card-heading {
    font-size: 20px;
    margin-bottom: 10px;
    color: #1ABC9C !important; /* Accent color with higher specificity */
    font-weight: 600; /* Slightly thicker font weight for visibility */
  }
  
  /* Input field styling */
  input {
    width: 100%;
    padding: 12px; /* Increase the padding */
    border: 0px solid #ccc; /* Ensure the border is visible */
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px; /* Ensure the font size is appropriate */
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  /* Sidebar styling */
  .sidebar {
    width: 320px; /* Fixed width for sidebar */
    margin-right: 20px;
    display: flex;
    flex-direction: column;
  }
  
  /* List item styling */
  .list-item {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s;
  }
  
  .list-item:hover {
    background-color: #ECF0F1; /* Light Grey */
  }
  
  .list-item.active {
    background-color: #1ABC9C; /* Turquoise for active item */
    color: #ffffff; /* White text for active item */
  }
  @media (max-width: 768px) {
    .supplements {
      flex-direction: column; /* Stack elements vertically on smaller screens */
    }
  
    .supplements-sidebar {
      width: 100%; /* Full width for sidebar on mobile */
      margin-right: 0;
      margin-bottom: 20px; /* Add some space below the sidebar */
    }
  
    .conditions {
      flex-direction: column; /* Stack elements vertically on smaller screens */
    }
  
    .conditions-sidebar {
      width: 100%; /* Full width for sidebar on mobile */
      margin-right: 0;
      margin-bottom: 20px; /* Add some space below the sidebar */
    }
  }
  /* Add this to your common.css file */

@media (max-width: 600px) {
  .MuiTableCell-root {
    padding: 8px 4px;
  }

  .MuiTableCell-head {
    font-size: 0.875rem;
  }

  .MuiTableCell-body {
    font-size: 0.75rem;
  }

  .MuiButton-root {
    padding: 4px 8px;
    font-size: 0.75rem;
  }

  .MuiIconButton-root {
    padding: 4px;
  }

  .MuiAccordionSummary-root {
    padding: 0 8px;
  }

  .MuiAccordionDetails-root {
    padding: 8px;
  }
}
.main-text {
  color: #1ABC9C;
  font-weight: bold;
}

.sub-text {
  color: #070707;
}

.note-text {
  color: #060606;
  font-style: normal;
}

.section-heading {
  color: #333;
  font-weight: 600;
  margin-bottom: 16px;
}

.result-heading {
  color: #555;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 8px;
}

.result-text {
  color: #666;
  line-height: 1.6;
}



