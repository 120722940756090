@import './common.css';


/* Any App specific styles */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #F5F5F5; /* Light Grey */
  color: #2E2E2E; /* Dark Grey */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
}

html, body, #root {
  height: 100%;
  width: 100%;
}



a {
  text-decoration: none;
  color: inherit;
}

.MuiAppBar-root {
  background-color: #2C3E50; /* Dark Slate Blue */
  height: 64px; /* Set height of the header */
}

.MuiTabs-indicator {
  background-color: #1ABC9C; /* Turquoise */
}

.MuiTab-root {
  font-weight: 500;
}

.MuiTab-textColorInherit.Mui-selected {
  color: #1ABC9C; /* Turquoise */
}

.MuiTypography-h6 {
  font-weight: 600;
}


/* Scoped styles for embedded HTML content */
.embedded-html-content {
  color: initial;
  font-family: initial;
  line-height: initial;
}

.embedded-html-content * {
  color: inherit;
  font-family: inherit;
  line-height: inherit;
}

/* Reset footer styles to ensure they are not affected by embedded HTML content */
footer {
  color: white;
  a {
    color: white;
    text-decoration: none;
  }
}

.MuiBox-root {
  margin-left: 0 !important; /* Reset any unintended left margin */
  margin-right: 0 !important; /* Reset any unintended left margin */
  margin-bottom: 0 !important; /* Reset any unintended left margin */
}