.detail-content a {
    color: rgb(72, 147, 239); /* Change this to the desired color */
    text-decoration: underline; /* Add underline to differentiate from regular text */
  }
  
  .detail-content a:hover {
    color: rgb(49, 121, 150); /* Change color on hover */
    text-decoration: underline;
  }

  detail-component {
    display: float;
    flex-direction: row;
    align-items: center;
    justify-content: center ;
    margin-top: 10px;
    color: rgb(72, 147, 239); /* Change this to the desired color */
    text-decoration: underline; /* Add underline to differentiate from regular text */


    .card{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center ;
      margin-top: 10px;
      color: rgb(72, 147, 239); /* Change this to the desired color */
      text-decoration: underline; /* Add underline to differentiate from regular text */
    }
  }