/* Header.css */

body {  
    margin: 0;  
    font-family: 'Poppins', sans-serif;  
    background-color: #F5F5F5; /* Light Grey */  
    color: #2E2E2E; /* Dark Grey */  
}
nav a, nav a:link, nav a:visited {
    color: #ffffff !important; /* Original navigation link color */
    text-decoration: none !important; /* No underline for navigation links */
  }
  
  nav a:hover, nav a:focus {
    color: #cccccc !important; /* Slightly different hover color for navigation links */
    text-decoration: none !important; /* No underline on hover */
  }


.MuiAppBar-root {  
    background-color: #174f04; /* Update the header background color to ensure contrast */  
}

.MuiTabs-indicator {  
    background-color: #FFC107; /* Change the indicator color if necessary */  
}

.MuiTab-root {  
    font-weight: 500;  
}

.MuiTab-textColorInherit.Mui-selected {  
    color: #FFC107; /* Ensure the selected tab color provides good contrast */  
}


.header {
    position: relative;
    z-index: 1000; /* Ensure the header stays on top */
  }